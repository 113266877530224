import React from 'react'
import { Helmet } from 'react-helmet'
import constants from '../util/constants'

const Canonical = ({ slug }) => {
  const url =
    slug === 'home' ? `${constants.siteUrl}/` : `${constants.siteUrl}/${slug}/`
  return (
    <Helmet>
      <link rel="canonical" href={url} />
    </Helmet>
  )
}

export default Canonical
