// src/templates/page/index.js

import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../layouts'
import Seo from '../components/Seo'
import PageTitle from '../components/PageTitle'
import Canonical from '../components/Canonical'

const Page = ({ data }) => {
  const page = data.page

  return (
    <Layout>
      <Seo title={page.title} />
      <Canonical slug={page.slug} />
      {page.title.toLowerCase() !== 'home' && <PageTitle title={page.title} />}

      <div
        className={page.title.toLowerCase()}
        dangerouslySetInnerHTML={{ __html: page.content }}
      />
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query PageById($id: String!) {
    page: wpPage(id: { eq: $id }) {
      id
      slug
      content
      title
      date(formatString: "MMMM DD, YYYY")
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                placeholder: TRACED_SVG
                layout: FULL_WIDTH
              )
            }
          }
        }
      }
    }
  }
`
